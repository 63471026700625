<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card :heading="page.title">
      <drag-people :data="params"></drag-people>
    </base-card>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    page: {
      title: 'Gerenciar membros'
    },
    params: {
      rolesParamsSearch: '',
      roles: '',
      title: '',
      linkGetPeopleAdm: '',
      linkSaveAdm: ''
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Instituição',
        disabled: false,
        to: '/organization',
        exactPath: true
      },
      {
        text: 'Administradores',
        disabled: false,
        to: '/organization/admins'
      }
    ]
  }),
  created () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    const rolesParams = this.$route.params.roles
    if (rolesParams === 'admin') {
      this.page.title = 'Adicionar administradores à instituição'
      this.params = {
        roles: 'ADMIN',
        title: 'Administradores',
        titleAdm: 'Administradores na instituição',
        linkGetPeopleAdm: `/organizations/${this.id}/people?roles[]=ADMIN&roles[]=TECHNICAL&roles[]=FINANCIAL&roles[]=ORG_PEDAGOGICAL_COORDINATOR`,
        linkSaveAdm: `/organizations/${this.id}/people/sync`
      }
      this.breadcrumbs.push({
        text: `Administrar ${this.params.title.toLowerCase()}`,
        disabled: true,
        to: '/organization/people'
      })
    }
  }
}
</script>
